import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
  margin: 26px auto;
  padding-bottom: 40px;
  width: 100%;

  p {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 16px;
    color: #e7d9e8;
  }
  h2 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    color: #e7d9e8;
  }
`;
