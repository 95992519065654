import styled from "styled-components";

export const MobileShowroomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 768px;
  width: 100%;
  margin: 40px auto 0px auto;
  padding: 20px 0px;
  background: linear-gradient(#000 0%, #674852 100%);

  .showroom {
    position: relative;
    width: 310px;
    height: 310px;
    margin: 0px auto 20px auto;

    .bg {
      width: 310px;
      height: 310px;
      object-fit: cover;
      position: relative;
      opacity: 0.17;
      z-index: 0;
    }

    .hatch {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 290px;
      height: 290px;
      object-fit: contain;
    }
    .open-title {
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Poppins";
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      padding: 4px 20px;
      border-radius: 40px;
      background: rgba(219, 0, 0, 0.6);
    }
  }
  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    width: 90%;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    color: #da9d31;
    margin: 0px auto;
    margin-top: 20px;
  }

  .text {
    width: 90%;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 18px;
    line-height: 1.3;
    color: #e7d9e8;
    margin: 0px auto;
    margin-bottom: 12px;
    margin-top: 20px;
  }
`;
