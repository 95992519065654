import styled from "styled-components";

export const ShowroomContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-sta;
  max-width: 1024px;
  gap: 40px;
  width: 100%;
  height: auto;
  min-height: 580px;
  margin: 60px auto 0px auto;
  background: radial-gradient(circle at center, #674852 0%, #000 50%);

  .buttons {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.8);
      border: none;
      outline: none;
      color: #ae0018;
      font-weight: 800;
      font-size: 28px;
      text-align: center;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .showroom-bg {
    position: relative;
    width: 450px;
    height: 450px;
    margin: 20px auto 0px auto;

    .bg {
      width: 450px;
      height: 450px;
      object-fit: cover;
      position: relative;
      opacity: 0.17;
      z-index: 0;
    }

    .lazyload-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .react-transform-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 450px;
      height: 450px;
      object-fit: contain;
      img {
        width: 90%;
        height: 450px;
        object-fit: contain;
        margin: 0px auto;
      }
    }

    img {
      width: 90%;
      height: 450px;
      object-fit: contain;
      margin: 0px auto;
    }

    .experience-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        padding: 2px 16px;
        border-radius: 40px;
        background: rgba(219, 0, 0, 0.6);
        cursor: pointer;
        text-align: center;
        margin: 4px auto 0px auto;
        display: inline-block;
        span {
          font-family: "Poppins";
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }

  .showroom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin: 0px auto;
    width: 500px;
    .title {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 40px;
      line-height: 1.1;
      text-align: left;
      color: #da9d31;
      margin-bottom: 20px;
    }
    .content {
      font-family: "Poppins";
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
      text-align: left;
      color: #e7d9e8;
      margin-bottom: 20px;
    }
    .image-list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
