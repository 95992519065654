import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 14px;
  cursor: pointer;
  background-color: {
    props => props.background ? props.background : "#fff"
  };

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    border-radius : 10px;
    padding : 3px;
  }

  .active-image{
      border : 2px solid #7E1B1B;
  }
`;
