import styled from "styled-components";

export const OneLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  height: 100px;
  background-color: #da9d31;
  margin: 0px auto;
  border-radius: 25px;
  position: relative;
  z-index: 5;
  padding: 36px;
  gap: 20px;
  .title {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 54px;
    @media (max-width: 1024px) {
      font-size: 40px;
    }
    color: #db0000;
  }
  .text {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    line-height: 1.1;
    @media (max-width: 1024px) {
      font-size: 18px;
    }
    color: #1c1e2f;
    max-width: 800px;
  }
  .social-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;
    list-style: none;
    li {
      a {
        text-decoration: none;
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
        }
      }
    }
    li:not(:last-child) {
      margin-right: 20px;
    }
  }
`;
