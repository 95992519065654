import { React, useEffect } from "react";
import GlobalStyles, * as global from "./styles/Global";
import { ThemeProvider } from "styled-components";
import ReactGA from "react-ga";

import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import "./i18n";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/HomePage";
import ExperiencePage from "./pages/ExperiencePage";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-MPWDD534WC");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={global.theme}>
        <>
          <GlobalStyles />
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/home" component={HomePage} exact />
            <Route path="/experience/:id" component={ExperiencePage} exact />
            <Route path="/experience" component={ExperiencePage} exact />
            <Route component={NotFound} />
          </Switch>
        </>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
