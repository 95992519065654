import styled from "styled-components";

export const MobileTokenomyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  border-radius: 0px;
  background: linear-gradient(
    rgba(232, 65, 66, 0.5) 0%,
    rgba(165, 48, 50, 0.25) 100%
  );
  position: relative;
  max-width: 1024px;
  width: 100%;
  margin: 0px auto 30px auto;

 
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      max-width: 300px;
      margin: 0px auto;
      margin-top: -70px;
      margin-bottom: 20px;
      li{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 100px;
          height: 100px;
        }
        span{
          font-family: "Poppins";
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    color: #ebc4c4;
        }
      }
    }

  
   
 
 
  span {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #e7d9e8;
    margin-bottom: 12px;
    max-width: 850px;
  }
  h3 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #ee8600;
    max-width: 850px;
  }

  .avax {
    margin: 0px auto;
    margin-bottom: 18px;
  }
`;
