import styled from "styled-components";

export const MobileBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 768px;
  background-color: "#000";
  margin: 0 auto;
  position: relative;

  .background-image {
    width: 100%;
    object-fit: contain;
    z-index: 0;
    margin: 0px auto;
    margin-top: 160px;
  }

  .logo {
    height: 250px;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  h2 {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 16px;
    z-index: 1;
    margin: 0px auto;
    margin-top: -30px;
    width: 90%;
    max-width: 960px;
    text-align: center;
    span {
      font-family: "Poppins";
      font-weight: 600;
    }
  }

  .social-links {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    margin-top: 20px;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      li {
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
        a {
          &.gitbook {
            border: 1px solid #da9d31;
            border-radius: 20px;
            width: 160px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: #fff;
            font-size: 14px;

            text-transform: uppercase;
            span {
              font-weight: 800 !important;
            }
            img {
              width: 36px;
              height: 36px;
              object-fit: contain;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }
      }
    }
    .description {
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 300;
      max-width: 380px;
      width: 80%;
      color: #f5fbfd;
      margin-left: 20px;
      span {
        font-family: "Poppins";
        font-weight: 600;
      }
    }
  }
`;
