import styled from "styled-components";

export const TokenomyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px;
  border-radius: 40px;
  background: linear-gradient(
    rgba(232, 65, 66, 0.5) 0%,
    rgba(165, 48, 50, 0.25) 100%
  );
  position: relative;
  max-width: 1024px;
  width: 100%;
  margin: 30px auto;

  .col-2{ 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      li{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 200px;
          height: 200px;
        }
        span{
          font-family: "Poppins";
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    color: #ebc4c4;
        }
      }
    }
    h2 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    color: #ebc4c4;
    margin-bottom: 12px;
    max-width: 850px;
  }
  }

 
  span {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #e7d9e8;
    margin-bottom: 18px;
    max-width: 850px;
  }
  h3 {
    a {
      text-decoration: none;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      color: #ee8600;
      max-width: 850px;
    }
  }

  .avax {
    position: absolute;
    right: 15px;
    bottom: 15px;
    @media (max-width: 870px) {
      position: static;
      margin-top: 18px;
    }
  }
`;
