import React from "react";

const NotFound = () => (
  <>
    <div className="notfound">
      <h1>404 Not Found</h1>
    </div>
  </>
);

export default NotFound;
