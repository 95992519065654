import React, { useEffect } from "react";
import { ExperienceContainer } from "../styles/ExperienceContainer.styled";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BrowserRouter as Router, useParams } from "react-router-dom";

const ExperiencePage = () => {
  const [loaded, setLoaded] = React.useState(false);
  const { id } = useParams();

  return (
    <>
      <ExperienceContainer>
        <TransformWrapper
          initialScale={1}
          id="transformid"
          style={{ visibility: loaded && id >= 0 ? "visible" : "hidden" }}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent className="item">
                <img
                  id="transformid"
                  src={"/images/sr" + id + ".png"}
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  style={{
                    visibility: loaded && id >= 0 ? "visible" : "hidden",
                  }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>

        <>
          <div
            className="loading-container"
            style={{ visibility: loaded && id >= 0 ? "hidden" : "visible" }}
          >
            <h1>4K NFT Loading</h1>
            <img
              className="loading-gif"
              src="/images/loading.gif"
              alt="loading"
            />
          </div>
        </>
      </ExperienceContainer>
    </>
  );
};
export default ExperiencePage;
