import styled from "styled-components";

export const MobileDescriptionContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  list-style: none;
  background-image: url("/images/feature-back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 0px 30px 0px;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1024px;
    margin: 40px auto;
    gap: 60px;
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0 auto;
      min-height: 500px;
      width: 90%;
      border-radius: 20px;
      padding: 20px;
      padding-top: 90px;
      background: linear-gradient(
          to bottom,
          rgba(88, 99, 82, 0.7) 0%,
          rgba(0, 1, 2, 0) 100%
        ),
        transparent;
      &:first-child {
        h2 {
          border-bottom: 2px solid #6b5f6c;
        }
        background: linear-gradient(
            to bottom,
            rgba(57, 41, 68, 0.7) 0%,
            rgba(0, 1, 2, 0) 100%
          ),
          transparent;
      }
      &:last-child {
        h2 {
          border-bottom: 2px solid #5f616c;
        }
        background: linear-gradient(
            to bottom,
            rgba(82, 84, 99, 0.7) 0%,
            rgba(0, 1, 2, 0) 100%
          ),
          transparent;
      }
      img {
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        height: 160px;
        object-fit: contain;
      }
      h2 {
        border-bottom: 2px solid #5f616c;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 36px;
        color: #e7d9e8;
        text-align: center;
        line-height: 1;
        padding-bottom: 12px;
        margin-bottom: 20px;
      }
      span {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 18px;
        color: #e7d9e8;
        text-align: left;
        line-height: 1.3;
        letter-spacing: 0.5px;
      }
    }
  }
`;
