import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    whitelist: ["en", "tr"],
    detection: {
      order: [
        "path",
        "navigator",
        "queryString",
        "cookie",
        "htmlTag",
        "subdomain",
      ],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      cache: ["localStorage", "cookie"],
      htmlTag: document.documentElement,
      checkWhitelist: true,
      cookieOptions: { path: "/", sameSite: "strict" },
      transWrapTextNodes: "span",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
